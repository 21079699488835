input::placeholder {
  color: #6b7280;
  font-family: 'manrope-google-font', sans-serif;
  font-size: 14px;
}

.p-chips:not(.p-disabled).p-focus .p-chips-multiple-container,
.p-inputswitch.p-focus .p-inputswitch-slider {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}

.p-component,
.p-inputtext {
  font-family: 'manrope-google-font', sans-serif;
  outline: none;
}

.custom-form {
  .p-inputtext:not(.p-chips-multiple-container) {
    height: 40px;
    font-size: 14px;
  }

  .p-dropdown.p-component,
  .p-multiselect.p-component {
    height: 40px;
  }
}

.p-placeholder {
  font-size: 14px;
}

.p-inputtext:enabled:focus {
  outline: 0 none;
  box-shadow: none;
}

p-calendar,
p-multiselect,
p-autocomplete {
  width: 100%;
}

.p-accordion .p-accordion-header .p-accordion-header-link {
  padding: 1.25rem;
  border: none;
  border-bottom: 1px solid #e5e7eb;
  color: black;
  background: transparent;
  font-weight: 600;
  border-radius: 6px;
  transition: box-shadow 0.2s;
  font-size: 14px;
}

.p-accordion .p-accordion-content {
  padding: 1.25rem;
  border: none;
  background: #ffffff;
  border-top: 0;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
}

.p-accordion-header-link {
  padding: 10px 0px !important;
}

.p-accordion-content {
  padding: 0px 0px !important;
}

.p-button:focus {
  box-shadow: none;
}

.p-dropdown {
  width: 100%;
}

.p-focus {
  box-shadow: none;
}

.action-buttons {
  .p-button {
    height: 40px;
    width: 40px;
  }
}

.p-button:not(
    .p-button-icon-only,
    .p-button-text,
    .p-dialog-header-icon,
    .p-button-secondary,
    .p-button-danger,
    .p-togglebutton,
    .custom-table-link-button
  ) {
  height: 40px;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 400;
  border: none;
  background-color: var(--primary-color);

  .p-button-label {
    color: white;
  }
}

.p-button:active {
  opacity: 0.8;
}

.p-button-outlined {
  border: 1px var(--primary-color) solid !important;
  background-color: white !important;

  .p-button-label {
    color: var(--primary-color) !important;
  }
}

.p-dropdown-item {
  padding: 10px 5px;
}

.p-tabview-title {
  color: var(--primary-color);
}

.p-dropdown-trigger,
.p-dropdown-clear-icon,
.p-datepicker-icon,
.p-multiselect-trigger-icon,
.p-multiselect-clear-icon,
.pi {
  color: var(--primary-color);
}

.p-column-filter-menu-button {
  color: var(--primary-color);
  opacity: 0.7;
  width: 23px;
  height: 25px;
}

.p-column-filter-menu-button:hover {
  color: var(--primary-color);
  opacity: 1;
}

.p-column-filter-menu-button-active {
  opacity: 1;
  background: #eff6ff;
}

.p-datatable .p-sortable-column.p-highlight:hover {
  color: var(--primary-color);
}

.p-datatable .p-sortable-column.p-highlight {
  background: hsl(214, 100%, 97%);
  color: var(--primary-color);
}

.p-datatable .p-sortable-column:not(.p-highlight):hover,
.p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon {
  color: var(--primary-color);
  opacity: 1;
}

.p-datatable .p-sortable-column-icon {
  color: var(--primary-color);
  opacity: 0.7;
}

p-calendar.ng-dirty.ng-invalid,
p-multiselect.ng-dirty.ng-invalid {
  input: {
    border: 1px var(--required) solid;
    color: var(--required);
  }

  .p-inputtext {
    border-color: var(--required);
    color: var(--required);
  }

  .p-multiselect {
    border-color: var(--required);
    color: var(--required);
  }

  .p-placeholder {
    color: var(--required);
  }
}

.error-dropdown {
  color: var(--required);

  .p-dropdown {
    border: 1px var(--required) solid;
    color: var(--required);
  }

  span {
    color: var(--required);
  }

  svg {
    stroke: var(--required);
    color: var(--required);
  }

  .pi {
    color: var(--required);
  }
}

p-chips.ng-dirty.ng-invalid > .p-chips > .p-inputtext {
  border-color: var(--required);
  color: var(--required);
}

p-chips.ng-dirty.ng-invalid {
  border-color: var(--required);

  .p-chips-input-token {
    input::placeholder {
      color: var(--required);
    }
  }
}

.error-textarea {
  color: var(--required);
  border: 1px var(--required) solid;
}

textarea.error-textarea::placeholder {
  color: var(--required);
}

.error-date {
  color: var(--required);

  input {
    color: var(--required);
    border: 1px var(--required) solid;
  }

  input::placeholder {
    color: var(--required);
  }

  svg {
    stroke: var(--required);
    color: var(--required);
  }
}

.drop-down-style {
  width: fit-content;
  padding: 6px 10px;
  border-radius: 12px;
  font-size: 14px;
  line-height: 1 !important;
  font-weight: 500;
}

.active-chip {
  @extend .drop-down-style;
  background-color: #409e6438;
  color: #409e64 !important;
}

.inactive-chip {
  @extend .active-chip;
  background-color: #0d192d1a;
  color: #0e346bb2 !important;
}

.under-construction-chip {
  @extend .active-chip;
  background-color: #f6b81933;
  color: #e97037 !important;
}

.task-not-started {
  @extend .drop-down-style;
  color: #0e346bb2;
  background-color: #0d192d1a;
}

.task-in-progress,
.warranty-type-oem {
  @extend .task-not-started;
  color: #2777bd;
  background-color: #2777bd1f;
}

.task-completed,
.claim-insurance {
  @extend .task-not-started;
  color: #409e64;
  background-color: #409e641a;
}

.task-compliance {
  @extend .drop-down-style;
  color: #0e346b;
  background-color: #0e346b1a;
  width: fit-content;
}

.task-invoicing {
  @extend .task-compliance;
  color: #409e64;
  background: #409e641a;
}

.task-regulatory,
.warranty-workmanship {
  @extend .task-compliance;
  color: #e06126;
  background-color: #e061261a;
}

.claim-equipment-warranty {
  @extend .drop-down-style;
  color: #0e346b;
  background-color: #0e346b1a;
  width: fit-content;
}

.claim-other {
  @extend .claim-equipment-warranty;
  background: #f6b81933;
  color: #e97037;
}

.delayed-container {
  .pi-minus-circle {
    color: #ca0b0f !important;
  }

  > div {
    padding-top: 3px;
  }

  p {
    padding: 0px;
    margin: 5px 0px;
    color: #ca0b0f;
    font-size: 14px;
    font-weight: 500;
  }
}

.days-left-container {
  .pi {
    color: #e06126 !important;
  }
  > div {
    padding-top: 3px;
  }

  p {
    padding: 0px;
    margin: 5px 0px;
    color: #e06126;
    font-size: 14px;
    font-weight: 500;
  }
}

.p-dialog-content {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.p-dialog {
  background-color: white;
}

.disable-input-icon {
  .pi,
  .p-dropdown-trigger,
  .p-datepicker-icon {
    color: gray;
  }

  .p-input-icon {
    .icon-text {
      color: gray;
    }
  }
}

.p-accordion-toggle-icon,
.p-tree-toggler-icon {
  color: var(--primary-color);
}

.pv_energy,
.approved {
  @extend .drop-down-style;
  color: #409e64 !important;
  background-color: #409e641a;
}

.bess_energy {
  @extend .drop-down-style;
  color: #0e346b !important;
  background-color: #0e346b1a;
}

.hybrid_energy,
.analyst_review {
  @extend .drop-down-style;
  color: #e97037 !important;
  background-color: #f6b81933;
}

.wind_energy,
.manager_review {
  @extend .drop-down-style;
  color: #2777bd !important;
  background-color: #2777bd1f;
}

.draft {
  @extend .drop-down-style;
  color: #0e346bb2 !important;
  background-color: #0d192d1a;
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
  background-color: var(--primary-color);
}

.p-overlaypanel:before,
.p-overlaypanel:after {
  left: calc(var(--overlayArrowLeft, 0) + 2.25rem);
}

.custom-filter-overlay.p-overlaypanel:before,
.custom-filter-overlay.p-overlaypanel:after {
  display: none;
}

.p-component.p-inputtext:disabled {
  opacity: 0.6;
  background-color: #0d192d0d;
}

.p-datatable .p-datatable-thead > tr > th {
  padding: 1rem 5px;
}

.p-datatable .p-datatable-tbody > tr > td {
  padding: 1rem 5px;
}

.p-datatable .p-sortable-column .p-sortable-column-icon {
  margin: 0px;
}

.header-cell-item {
  overflow-x: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
}

.p-treenode-label {
  color: black;
}

.table-row {
  .p-inputtext {
    padding: 5px 5px;
  }
}

.p-tree {
  /* width */
  ::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: none;
    border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgb(217, 215, 215);
    border-radius: 10px;
  }
}

.selected-status-chip {
  position: relative;
  bottom: 4px;

  > div {
    font-size: 14px;
  }
}

.integration-not_started {
  @extend .drop-down-style;
  background-color: #0d192d1a;
  letter-spacing: 0.03rem;
}

.integration-in_progress {
  @extend .integration-not_started;
  color: #2777bd;
  background-color: #2777bd1f;
}

.integration-success {
  @extend .integration-not_started;
  color: #409e64;
  background-color: #409e641f;
}

.integration-failed {
  @extend .integration-not_started;
  color: #a0090c;
  background-color: #ca0b0f1a;
}

.p-datatable-thead {
  background-color: #f9fafb;
}

.bordered-accordion {
  .p-accordion-header-link {
    border: none !important;
  }

  .p-accordion-tab {
    border: 1px solid #0000001a;
    border-radius: 10px;
    overflow: hidden;
    padding: 10px 15px 10px 14px;
    margin-bottom: 15px;
  }

  .p-accordion-tab-active {
    padding-bottom: 20px;
  }

  .p-toggleable-content {
    padding-left: 25px;
  }

  .p-accordion-header-text {
    font-size: 16px;
    font-weight: 700;
  }
}

.summary-accordion-tab {
  background: #edf8fe;
  border: 1px solid #075a9e33 !important;

  .p-accordion-content {
    background: transparent;
  }
}

.summary-value {
  font-size: 16px !important;
  font-weight: 700 !important;
}

.summary-label {
  span {
    font-size: 14px !important;
    font-weight: 500 !important;
    color: #0e346b !important;
  }
}
